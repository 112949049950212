// src/BookingSystem/PendingRequestsList.js
import React, { useState } from 'react';
import './PendingRequestsList.css';

const PendingRequestsList = ({ requests, onApprove, onReject, venues }) => {
  const [selectedVenues, setSelectedVenues] = useState({});

  const handleVenueChange = (requestId, venue) => {
    setSelectedVenues({ ...selectedVenues, [requestId]: venue });
  };

  const handleApprove = (request) => {
    const selectedVenue = selectedVenues[request._id];
    if (!selectedVenue) {
      alert('請選擇一個場地');
      return;
    }
    onApprove(request._id, selectedVenue);
  };

  return (
    <div className="pending-requests-list">
      {requests.length === 0 ? (
        <p>目前沒有待審核的預約請求</p>
      ) : (
        requests.map((request) => (
          <div key={request._id} className="request-item">
            <h4>預約請求 - {request.userName}</h4>
            <p>開始時間：{new Date(request.startTime).toLocaleString()}</p>
            <p>結束時間：{new Date(request.endTime).toLocaleString()}</p>
            <p>參與者：{request.participants.filter(p => p).join(', ')}</p>
            <p>備註：{request.notes}</p>
            <div className="venue-selection">
              <label>選擇場地：</label>
              <select 
                value={selectedVenues[request._id] || ''}
                onChange={(e) => handleVenueChange(request._id, e.target.value)}
              >
                <option value="">請選擇場地</option>
                {venues.map((venue) => (
                  <option key={venue} value={venue}>
                    {venue}
                  </option>
                ))}
              </select>
            </div>
            <div className="action-buttons">
              <button 
                onClick={() => handleApprove(request)} 
                className="approve-btn"
              >
                批准
              </button>
              <button 
                onClick={() => onReject(request._id)} 
                className="reject-btn"
              >
                拒絕
              </button>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default PendingRequestsList;