import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import './BookingRequestForm.css';

const BookingRequestForm = ({ onSubmit, onCancel, currentUserId }) => {
  const [formData, setFormData] = useState({
    startTime: null,
    endTime: null,
    participants: ['', '', '', '', ''],
    notes: '',
    recurrence: 'none',
    recurrenceEnd: '3months'
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleParticipantChange = (index, value) => {
    const newParticipants = [...formData.participants];
    newParticipants[index] = value;
    setFormData({ ...formData, participants: newParticipants });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!formData.startTime || !formData.endTime) {
      alert('請選擇開始和結束時間');
      return;
    }
    onSubmit({ ...formData, userId: currentUserId });
  };

  return (
    <form onSubmit={handleSubmit} className="booking-request-form">
      <h3>提交預約請求</h3>
      <div className="form-group">
        <label>開始時間</label>
        <DatePicker
          selected={formData.startTime}
          onChange={(date) => setFormData({ ...formData, startTime: date })}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={30}
          timeCaption="時間"
          dateFormat="yyyy-MM-dd HH:mm"
          minDate={new Date()}
          placeholderText="選擇開始時間"
        />
      </div>
      <div className="form-group">
        <label>結束時間</label>
        <DatePicker
          selected={formData.endTime}
          onChange={(date) => setFormData({ ...formData, endTime: date })}
          showTimeSelect
          timeFormat="HH:mm"
          timeIntervals={30}
          timeCaption="時間"
          dateFormat="yyyy-MM-dd HH:mm"
          minDate={formData.startTime || new Date()}
          placeholderText="選擇結束時間"
        />
      </div>
      <div className="form-group">
        <label>參與者</label>
        {formData.participants.map((participant, index) => (
          <input
            key={index}
            type="text"
            value={participant}
            onChange={(e) => handleParticipantChange(index, e.target.value)}
            placeholder={`參與者 ${index + 1}`}
          />
        ))}
      </div>
      <div className="form-group">
        <label>備註</label>
        <textarea
          name="notes"
          value={formData.notes}
          onChange={handleChange}
          placeholder="請輸入備註（選填）"
        />
      </div>
      <div className="form-group">
        <label>週期</label>
        <select
          name="recurrence"
          value={formData.recurrence}
          onChange={handleChange}
        >
          <option value="none">無週期</option>
          <option value="weekly">每週</option>
          <option value="biweekly">每兩週</option>
          <option value="monthly">每月</option>
        </select>
      </div>
      {formData.recurrence !== 'none' && (
        <div className="form-group">
          <label>週期長度</label>
          <select
            name="recurrenceEnd"
            value={formData.recurrenceEnd}
            onChange={handleChange}
          >
            <option value="3months">3個月</option>
            <option value="6months">半年</option>
          </select>
        </div>
      )}
      <div className="form-actions">
        <button type="submit" className="submit-btn">提交請求</button>
        <button type="button" onClick={onCancel} className="cancel-btn">取消</button>
      </div>
    </form>
  );
};

export default BookingRequestForm;