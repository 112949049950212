// src/BookingSystem/Dashboard.js
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Calendar, momentLocalizer } from 'react-big-calendar';
import moment from 'moment';
import 'moment/locale/zh-tw';
import 'react-big-calendar/lib/css/react-big-calendar.css';
import UpdateProfile from './UpdateProfile';
import BookingForm from './BookingForm';
import BookingRequestForm from './BookingRequestForm';
import PendingRequestsList from './PendingRequestsList';
import './Dashboard.css';

moment.locale('zh-tw');
const localizer = momentLocalizer(moment);

// 固定的場地列表
const venues = ['1A', '2A', '2B', '2C', '2D', '2E', '3A', '3B', '3C', '3D', '3E', '3F', 'X', 'Y', 'Z'];

const messages = {
  allDay: '全天',
  previous: '往前',
  next: '往後',
  today: '今天',
  month: '月',
  week: '週',
  day: '日',
  agenda: '行程表',
  date: '日期',
  time: '時間',
  event: '預約',
  noEventsInRange: '此範圍內沒有預約',
  showMore: total => `+${total} 更多`
};

const formats = {
  timeGutterFormat: 'HH:mm',
  eventTimeRangeFormat: ({ start, end }) => {
    return `${moment(start).format('HH:mm')} - ${moment(end).format('HH:mm')}`;
  },
};

const Dashboard = ({ user, onUpdateUser }) => {
  const [showUpdateProfile, setShowUpdateProfile] = useState(false);
  const [events, setEvents] = useState([]);
  const [showBookingForm, setShowBookingForm] = useState(false);
  const [showBookingRequestForm, setShowBookingRequestForm] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [pendingRequests, setPendingRequests] = useState([]);
  const [showPendingRequests, setShowPendingRequests] = useState(false);
  const bookingFormRef = useRef(null);
  const [users, setUsers] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    console.log('User object:', user);
    fetchBookings();
    if (user.role === 'admin') {
      fetchUsers();
      fetchPendingRequests();
    }
  }, [user]);

  const fetchBookings = async () => {
    try {
      const response = await fetch('/api/bookings', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      const data = await response.json();
      const formattedEvents = data.map(booking => ({
        ...booking,
        start: new Date(booking.startTime),
        end: new Date(booking.endTime),
        title: booking.title,
        userId: booking.userId
      }));
      console.log('Formatted events:', formattedEvents);
      setEvents(formattedEvents);
    } catch (error) {
      console.error('Error fetching bookings:', error);
    }
  };

  const fetchUsers = async () => {
    try {
      const response = await fetch('/api/bookings/users', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch users');
      }
      const data = await response.json();
      setUsers(data);
    } catch (error) {
      console.error('Error fetching users:', error);
    }
  };

  const fetchPendingRequests = async () => {
    try {
      const response = await fetch('/api/booking-requests/pending', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      if (!response.ok) {
        throw new Error('Failed to fetch pending requests');
      }
      const data = await response.json();
      setPendingRequests(data);
    } catch (error) {
      console.error('Error fetching pending requests:', error);
    }
  };

  const handleSelectSlot = ({ start }) => {
    if (user.role === 'admin') {
      setSelectedEvent(null);
      setShowBookingForm(true);
      setShowBookingRequestForm(false);
      scrollToBookingForm();
    } else {
      setSelectedEvent(null);
      setShowBookingRequestForm(true);
      setShowBookingForm(false);
      scrollToBookingForm();
    }
  };

  const handleSelectEvent = (event) => {
    if (user.role === 'admin' || event.userId === user._id) {
      setSelectedEvent(event);
      setShowBookingForm(true);
      setShowBookingRequestForm(false);
      scrollToBookingForm();
    } else {
      alert(`場地 ${event.venue} 在此時段已被預約`);
    }
  };

  const handleBookingSubmit = async (bookingData) => {
    try {
      let response;
      if (selectedEvent) {
        if (bookingData.updateType === 'all') {
          const confirmUpdate = window.confirm('您確定要更新所有週期預約嗎？這可能會影響之後的預約。');
          if (!confirmUpdate) {
            return;
          }
        }

        response = await fetch(`/api/bookings/${selectedEvent._id}`, {
          method: 'PUT',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify(bookingData),
        });
      } else {
        response = await fetch('/api/bookings', {
          method: 'POST',
          headers: { 
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          },
          body: JSON.stringify(bookingData),
        });
      }
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit booking');
      }

      const responseData = await response.json();

      if (responseData.hasConflicts) {
        const conflictMessage = `有 ${responseData.conflictingBookings.length} 個預約存在衝突。\n已成功更新 ${responseData.updatedBookings.length} 個預約。\n是否要查看衝突詳情？`;
        const viewConflicts = window.confirm(conflictMessage);
        if (viewConflicts) {
          console.log('衝突的預約:', responseData.conflictingBookings);
        }
      } else {
        alert('預約更新成功！');
      }

      setShowBookingForm(false);
      fetchBookings();
    } catch (error) {
      console.error('Error submitting booking:', error);
      alert(error.message);
    }
  };

  const handleBookingRequestSubmit = async (requestData) => {
    try {
      const response = await fetch('/api/booking-requests', {
        method: 'POST',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify(requestData),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to submit booking request');
      }
  
      alert('預約請求已提交，等待管理員審核。');
      setShowBookingRequestForm(false);
      // 可能需要刷新預約列表或待處理的請求列表
    } catch (error) {
      console.error('Error submitting booking request:', error);
      alert(error.message);
    }
  };

  const handleApproveRequest = async (requestId, venue) => {
    try {
      const response = await fetch(`/api/booking-requests/${requestId}/review`, {
        method: 'PUT',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ status: 'approved', venue }),
      });

      if (!response.ok) {
        throw new Error('Failed to approve request');
      }

      alert('預約請求已批准');
      fetchPendingRequests();
      fetchBookings();
    } catch (error) {
      console.error('Error approving request:', error);
      alert(error.message);
    }
  };

  const handleRejectRequest = async (requestId) => {
    try {
      const response = await fetch(`/api/booking-requests/${requestId}/review`, {
        method: 'PUT',
        headers: { 
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        },
        body: JSON.stringify({ status: 'rejected' }),
      });

      if (!response.ok) {
        throw new Error('Failed to reject request');
      }

      alert('預約請求已拒絕');
      fetchPendingRequests();
    } catch (error) {
      console.error('Error rejecting request:', error);
      alert(error.message);
    }
  };

  const handleDeleteBooking = async (deleteType) => {
    try {
      const deleteTypeStr = deleteType === 'all' ? 'all' : 'single';
      
      const response = await fetch(`/api/bookings/${selectedEvent._id}?deleteType=${deleteTypeStr}`, {
        method: 'DELETE',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
      
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to delete booking');
      }
      
      const result = await response.json();
      alert(result.message);
      setShowBookingForm(false);
      fetchBookings();
    } catch (error) {
      console.error('Error deleting booking:', error);
      alert(error.message);
    }
  };

  const handleExport = async () => {
    if (!selectedEvent) {
      alert('請先選擇一個預約');
      return;
    }
    try {
      const response = await fetch(`/api/bookings/export/${selectedEvent._id}`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      if (!response.ok) {
        throw new Error('導出失敗');
      }
  
      const blob = await response.blob();
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `booking_${selectedEvent._id}.txt`);
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error exporting booking:', error);
      if (error.response && error.response.status === 403) {
        alert('只有管理員可以導出預約');
      } else {
        alert('導出預約時發生錯誤');
      }
    }
  };

  const eventStyleGetter = (event, start, end, isSelected) => {
    const isOwner = event.userId === user._id;
    const isAdmin = user.role === 'admin';
    const style = {
      backgroundColor: isOwner || isAdmin ? event.userColor : '#D3D3D3',
      color: 'black',
      border: 'none',
      cursor: 'pointer',
    };
    return { style };
  };

  const scrollToBookingForm = () => {
    bookingFormRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/secure-booking-yT9x2');
  };

  return (
    <div className="dashboard-container">
      <header className="dashboard-header">
        <div className="user-info">
          <h1>
            您好
            <br />
            {user.name}
            <div className="color-square" style={{ backgroundColor: user.color }}></div>
          </h1>
          <p className="role">角色：{user.role}</p>
          <button className="logout-button" onClick={handleLogout}>
            登出
          </button>
        </div>
        <button className="dashboard-updateprofile-button" onClick={() => setShowUpdateProfile(!showUpdateProfile)}>
          {showUpdateProfile ? '取消修改個人資料' : '修改個人資料'}
        </button>
      </header>
      
      {showUpdateProfile && (
        <div className="update-profile-container">
          <UpdateProfile user={user} onUpdateUser={onUpdateUser} />
        </div>
      )}
      
      <div className="header-row">
        <div className="left-buttons">
          {user.role === 'admin' && (
            <button 
              className="create-booking-button" 
              onClick={() => {
                setSelectedEvent(null);
                setShowBookingForm(true);
                setShowBookingRequestForm(false);
                scrollToBookingForm();
              }}
            >
              新增預約
            </button>
          )}
          {user.role !== 'admin' && (
            <button 
              className="create-booking-request-button" 
              onClick={() => {
                setSelectedEvent(null);
                setShowBookingRequestForm(true);
                setShowBookingForm(false);
                scrollToBookingForm();
              }}
            >
              提交預約請求
            </button>
          )}
        </div>
        <h2>場地預約系統</h2>
        <div className="right-buttons">
          {user.role === 'admin' && (
            <>
              <button 
                className="export-booking-button" 
                onClick={handleExport}
                disabled={!selectedEvent}
              >
                匯出預約
              </button>
              <button 
                className="view-pending-requests-button" 
                onClick={() => setShowPendingRequests(!showPendingRequests)}
              >
                查看待審核預約請求 ({pendingRequests.length})
              </button>
            </>
          )}
        </div>
      </div>
      
      <div className="calendar-and-form-container">
        <div className="calendar-container">
          <Calendar
            localizer={localizer}
            events={events}
            startAccessor="start"
            endAccessor="end"
            onSelectSlot={handleSelectSlot}
            onSelectEvent={handleSelectEvent}
            selectable
            step={30}
            timeslots={1}
            eventPropGetter={eventStyleGetter}
            style={{ height: '100%' }}
            messages={messages}
            formats={formats}
          />
        </div>
  
        {showBookingForm && (
          <div className="booking-form-container" ref={bookingFormRef}>
            <BookingForm
              onSubmit={handleBookingSubmit}
              onCancel={() => setShowBookingForm(false)}
              onDelete={handleDeleteBooking}
              initialData={selectedEvent}
              isCurrentUser={user.role === 'admin' || (selectedEvent && selectedEvent.userId === user._id)}
              currentUserId={user._id}
              isAdmin={user.role === 'admin'}
              users={users}
              venues={venues}
            />
          </div>
        )}

        {showBookingRequestForm && (
          <div className="booking-request-form-container" ref={bookingFormRef}>
            <BookingRequestForm
              onSubmit={handleBookingRequestSubmit}
              onCancel={() => setShowBookingRequestForm(false)}
              currentUserId={user._id}
            />
          </div>
        )}

        {showPendingRequests && (
          <div className="pending-requests-container">
            <h3>待審核預約請求</h3>
            <PendingRequestsList 
              requests={pendingRequests}
              onApprove={handleApproveRequest}
              onReject={handleRejectRequest}
              venues={venues}
            />
          </div>
        )}
      </div>
    </div>
  );
}

export default Dashboard;