import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
import image1 from './images/home1.jpg';

const HomePage = () => {
  const navigate = useNavigate();

  return (
    <div className="container">
      <img src={image1} alt="Home Image 1" className="image" />
      <div className="button-group">
        <button onClick={() => navigate('/about')} className="button">About</button>
        <button onClick={() => navigate('/blog')} className="button">Blog</button>
        <button onClick={() => navigate('/projects')} className="button">Projects</button>
        <a 
          href="https://luan-chen.com/secure-booking-yT9x2" 
          className="copyright-link"
          target="_blank"
          rel="noopener noreferrer"
        >
          Copyright © 2024 Luan-Chen
        </a>  
      </div>
    </div>
  );
}

export default HomePage;
